(function ($, site, prodcat) {
  site.template = site.template || {};
  const containersClass = '.js-product-full__image';
  const $containers = $(containersClass);
  const carouselControlsNextLabel = site?.translations?.elc_general?.next || 'next';
  const carouselControlsPreviousLabel = site?.translations?.elc_general?.previous || 'previous';
  const $prevArrow = `
        <div class="slick-prev-button slick--custom">
          <svg role="img" aria-label="${carouselControlsPreviousLabel}" class="icon icon--caret--left">
            <use xlink:href="#caret--left"></use>
          </svg>
        </div>`;
        const $nextArrow = `
        <div class="slick-next-button slick--custom">
          <svg role="img" aria-label="${carouselControlsNextLabel}" class="icon icon--caret--right">
            <use xlink:href="#caret--right"></use>
          </svg>
        </div>`;
  const sppSlide = '.js-spp-carousel__slide';
  const hideClass = 'product-full-image--hidden';

  const productBadgeDisplay = function ($carousel) {
    const $flagContainer = $('.js-product-flag__container', $(this));
    const sliderIndex = $carousel.find('.slick-current').attr('data-slick-index');

    $flagContainer.hide();
    if (sliderIndex === '0') {
      $flagContainer.show();
    }
  };

  // Initialize one carousel
  const initCarousel = function ($container) {
    const $sppImages = $('.js-product-full-image__carousel', $container);
    const $carousel = $('.js-spp-carousel', $sppImages)
    const $arrowsDiv = $('.carousel-controls', $container);
    const $dotsDiv = $('.carousel-dots', $container);
    const $thumbnailsContainer = $('.js-spp-thumbnails', $container);
    
    const settings = {
      slide: sppSlide,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      appendArrows: $arrowsDiv,
      appendDots: $dotsDiv,
      prevArrow: $prevArrow,
      nextArrow: $nextArrow
    };
    const fullscreenSettings = {
      slide: sppSlide,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      fade: true
    };

    $([$arrowsDiv, $dotsDiv]).each(function () {
      $(this).on('click', function () {
       productBadgeDisplay($carousel);
      });
    });

    // Init this carousel with our settings
    $carousel.not('.slick-initialized').slick(settings);
    $(document).trigger('product.carousel-loaded');

    // Update the thumbnails to highlight the correct thumbnail
    $carousel.on('afterChange', function (event, slick, currentSlide) {
      const $thumbnails = $('.js-spp-carousel__thumbnail', $thumbnailsContainer);
      productBadgeDisplay($carousel);      
      selectThumbnail($thumbnails, currentSlide)
    });

    // Update carousel on thumbnail select
    $thumbnailsContainer.each(function ($container) {
      const $thumbnails = $('.js-spp-carousel__thumbnail', $container);

      $thumbnails.first().addClass('active'); // Set the first as active on load
      $thumbnails.on('click', function () {
        const currentSlide = $thumbnails.index($(this));
        $carousel.slick('slickGoTo', currentSlide);
        selectThumbnail($thumbnails, currentSlide)
      });
    });

    $container.removeClass(hideClass)
  };

  // Toggle selecting thumbnail
  const selectThumbnail = function($thumbnails, currentSlide) {
    $thumbnails.removeClass('active'); // Remove other active states
    $thumbnails.eq(currentSlide).addClass('active'); // Add the active state to this item
    $(this).data('currentSlide', currentSlide);
  }

  // Initialize all carousels on the page
  const initAllCarousels = function() {
    $containers.each(function () {
      const $container = $(this);
      initCarousel($container);
    });
  }

  // Update product specific carousel when user selects different sku
  // eslint-disable-next-line no-unused-vars
  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    const $product = $(this);
    const $container = $(containersClass, $product);

    $container.addClass(hideClass);
    const sku = prodcat.data.getSku(skuBaseId);
    const prod = $product ? prodcat.data.getProduct($product.data('product-id')) : null;
    // Parse the SKU otherwise parse the defaultSku of the product or just the product image
    /* eslint-disable */
    const product_full_image = site.template.get({
      name: 'product_full_image_v1',
      data: sku ? sku : prod.defaultSku ? prod.defaultSku : prod
    });
    /* eslint-enable */

    // Timeout needed to smooth out fade in/out animation of carousel on sku change
    setTimeout(() => {
      $container.html($(product_full_image).html());
      initCarousel($container);
    }, 500);
  });

  Drupal.behaviors.productFullImageV1 = {
    attach: function () {
      initAllCarousels()

      // all purpuse re-init event for all carousels
      $(document).on('init-pdp-carousels', function () {
        initAllCarousels()
      });
    }
  };
})(jQuery, window.site || {}, window.prodcat || {});
