var YT = YT || {};

(function ($, site, prodcat) {
  site.template = site.template || {};
  Drupal.behaviors.productFullImageV1 = {
    prevArrow: '',
    nextArrow: '',
    hideClass: 'product-full-image--hidden',

    selector: {
      productContainer: '.js-product-full',
      container: '.js-product-full__image',
      carousel: '.js-spp-carousel',
      carouselSlide: '.js-spp-carousel__slide',
      carouselThumbsWrapper: '.js-spp-thumbnails',
      carouselThumbs: '.js-spp-carousel__thumbnail',
      carouselThumbsControls: '.js-thumbnails-carousel-controls',
      carouselVideoThumbs: '.js-spp-carousel__thumbnail--video',
      thumbsContainer: '.js-product-full-image__carousel-thumbs-container',
      carouselDots: '.js-carousel-dots',
      carouselControls: '.js-carousel-controls',
      altPosterWrapper: '.js-spp-alt-video-poster-wrapper',
      altPoster: '.js-spp-alt-video-poster',
      player: '.js-alt-video-wrapper',
      playIcon: '.js-video-play-icon',
      controlsBar: '.js-alt-video__control-bar',
      controlsPlay: '.js-alt-video__play',
      controlsPause: '.js-alt-video__pause',
      controlsMute: '.js-alt-video__mute',
      controlsUnmute: '.js-alt-video__unmute',
    },

    attached: false,
    isMobile: false,
    attach: function (context) {
      const self = this;
      if (self.attached) {
        return;
      }
      self.attached = true;
      const $containers = $(self.selector.container, context);
      let curentSku = '';
      self.generateArrows();
      // Initialize all carousels on the page
      $containers.each(function () {
        const $container = $(this);
        self.getDom($container);
        curentSku = $container.nodes.$productContainer.data('skuBaseId');

        if (curentSku && (parseInt(curentSku, 10) !== $container.nodes.$productContainer.data('defaultSkuBaseId'))) {
          // Call updateImages, if the skuSelect was already fired.
          self.updateImages($container.nodes.$productContainer, curentSku);
        } else {
          self.initAltVideo($container, true);
          self.initCarousel($container);
          self.initThumbnailCarousel($container);
          self.setEvents($container);
        }
      });
       // Update product specific carousel when user selects different sku
      // eslint-disable-next-line no-unused-vars
      $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
        const $product = $(this);
        self.updateImages($product, skuBaseId);
      });
      self.setDevice();
     
    },
    setDevice: function() {
      const self = this;

      self.isMobile = window.innerWidth <= 768;
      $(window).on('resize', _.debounce(function() {
        self.isMobile = window.innerWidth <= 768;
      }, 100));
    },
    getDom: function($container) {
      const self = this;
      $container.nodes = {};
      $container.nodes.$productContainer = $container.closest(self.selector.productContainer);
      $container.nodes.$carousel = $(self.selector.carousel, $container);
      $container.nodes.$carouselSlide = $(self.selector.carouselSlide, $container);
      $container.nodes.$arrowsDiv = $(self.selector.carouselControls, $container);
      $container.nodes.$dotsDiv = $(self.selector.carouselDots, $container);
      $container.nodes.$thumbnailsContainer = $(self.selector.thumbsContainer, $container);
      $container.nodes.$carouselThumbsWrapper = $(self.selector.carouselThumbsWrapper, $container.nodes.$thumbnailsContainer);
      $container.nodes.$carouselThumbs = $(self.selector.carouselThumbs, $container.nodes.$thumbnailsContainer);
      $container.nodes.$carouselThumbsControls = $(self.selector.carouselThumbsControls, $container.nodes.$thumbnailsContainer);
      $container.nodes.$altPoster = $(self.selector.altPoster, $container.nodes.$carousel);
      $container.nodes.$altPosterWrapper = $(self.selector.altPosterWrapper, $container.nodes.$carousel);
      if ($container.nodes.$altPosterWrapper.length > 0) {
        $container.nodes.$altPosterWrapper.each(function() {
          let $this = $(this);
          self.getVideoDom($this);
        })
      }
    },
    getVideoDom: function($videoWrapper) {
      const self = this;
      $videoWrapper.data('nodes', {});
      $videoWrapper.data('nodes').$altVideoPoster = $videoWrapper.find(self.selector.altPoster);
      $videoWrapper.data('nodes').$player = $videoWrapper.find(self.selector.player);
      $videoWrapper.data('nodes').$video = $videoWrapper.data('nodes').$player.find('video');
      $videoWrapper.data('nodes').$playIcon = $(self.selector.playIcon, $videoWrapper);
      $videoWrapper.data('nodes').$constrols = $(self.selector.controlsBar, $videoWrapper.data('nodes').$player);
      $videoWrapper.data('nodes').$play = $(self.selector.controlsPlay, $videoWrapper.data('nodes').$constrols);
      $videoWrapper.data('nodes').$pause = $(self.selector.controlsPause, $videoWrapper.data('nodes').$constrols);
      $videoWrapper.data('nodes').$mute = $(self.selector.controlsMute, $videoWrapper.data('nodes').$constrols);
      $videoWrapper.data('nodes').$unmute = $(self.selector.controlsUnmute, $videoWrapper.data('nodes').$constrols);
    },
    generateArrows: function () {
      const self = this;
      const carouselControlsNextLabel = site?.translations?.elc_general?.next || 'next';
      const carouselControlsPreviousLabel = site?.translations?.elc_general?.previous || 'previous';
      self.prevArrow = `
          <div class="slick-prev-button slick--custom">
            <svg role="img" aria-label="${carouselControlsPreviousLabel}" class="icon icon--caret--left">
              <use xlink:href="#caret--left"></use>
            </svg>
          </div>`;
      self.nextArrow = `
          <div class="slick-next-button slick--custom">
            <svg role="img" aria-label="${carouselControlsNextLabel}" class="icon icon--caret--right">
              <use xlink:href="#caret--right"></use>
            </svg>
          </div>`;
    },
    productBadgeDisplay: function ($carousel) {
      const $flagContainer = $('.js-product-flag__container', $(this));
      const sliderIndex = $carousel.find('.slick-current').attr('data-slick-index');
  
      $flagContainer.hide();
      if (sliderIndex === '0') {
        $flagContainer.show();
      }
    },
    // Initialize one carousel
    initCarousel: function ($container) {
      const self = this;
      const settings = {
        slide: self.selector.carouselSlide,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
        arrows: true,
        dots: true,
        appendArrows: $container.nodes.$arrowsDiv,
        appendDots: $container.nodes.$dotsDiv,
        prevArrow: self.prevArrow,
        nextArrow: self.nextArrow
      };
      // Init this carousel with our settings
      $container.nodes.$carousel.not('.slick-initialized').slick(settings);
      $(document).trigger('product.carousel-loaded');
      $container.nodes.$carouselThumbs.first().addClass('active');
      $container.removeClass(self.hideClass)
    },

    // Initialize thumbs carousel
    initThumbnailCarousel: function ($container) {
      const self = this;
      const settings = {
        slide: self.selector.carouselThumbs,
        vertical: true,
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 6,
        arrows: true,
        appendArrows: $container.nodes.$carouselThumbsControls,
        prevArrow: self.prevArrow,
        nextArrow: self.nextArrow,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5
            }
          }
        ]
      };
      // Init this carousel with our settings
      $container.nodes.$carouselThumbsWrapper.not('.slick-initialized').slick(settings);
      $container.nodes.$carouselThumbs.first().addClass('active');
      // Reset inline css, we only use it for transition
      $container.css({
        width: '',
        height: ''
      });
      $container.removeClass(self.hideClass);
    },
  
    // Toggle selecting thumbnail
    selectThumbnail: function($container, currentSlide) {
      $container.nodes.$carouselThumbs.removeClass('active');
      $container.nodes.$carouselThumbs.eq(currentSlide).addClass('active');
      $container.nodes.$carouselThumbs.data('currentSlide', currentSlide);
    },
  
    updateImages: function($product, skuBaseId) {
      const self = this;
      const $container = $(self.selector.container, $product);
      if ($container.length < 1) {
        return;
      }
      /**
       * Save the width / height before hiding.
       * Prevent page jumpiness on sku change.
       */
      $container.css({
        width: $container.width(),
        height: $container.height()
      });
      $container.addClass(self.hideClass);
      const sku = prodcat.data.getSku(skuBaseId);
      const prod = $product ? prodcat.data.getProduct($product.data('productId')) : null;
      const videoArr = prod?.videoArr || {};
      // Parse the SKU otherwise parse the defaultSku of the product or just the product image
      /* eslint-disable */
      const data = sku ? sku : prod.defaultSku ? prod.defaultSku : prod;
      const product_full_image = site.template.get({
        name: 'product_full_image_v1',
        data: { ...data, videoArr} 
      });
      /* eslint-enable */

      // Timeout needed to smooth out fade in/out animation of carousel on sku change.
      setTimeout(() => {
        $container.html($(product_full_image).html());
      }, 500);

      /**
       * Allow time for images to render while hidden. The styling needs images to proper get
       * width on slider init.
       */
      setTimeout(() => {
        self.getDom($container);
        self.initAltVideo($container, true);
        self.initCarousel($container);
        self.initThumbnailCarousel($container);
        self.setEvents($container);
      }, 700);
    },
    setEvents: function($container) {
      const self = this;
      $([$container.nodes.$arrowsDiv, $container.nodes.$dotsDiv]).once().each(function () {
        $(this).on('click', function () {
         self.productBadgeDisplay($container.nodes.$carousel);
        });
      });
      // Update the thumbnails to highlight the correct thumbnail
      $container.nodes.$carousel.on('afterChange', function (event, slick, currentSlide) {
        self.productBadgeDisplay($container.nodes.$carousel);
        self.selectThumbnail($container, currentSlide);
        $container.nodes.$carouselThumbsWrapper.slick('slickGoTo', currentSlide);
        if ($container.nodes.$altPoster.length > 0) {
          self.stopAllVideos($container);
        }
      });
      $container.nodes.$carouselThumbs.on('click', function () {
        const currentSlide = $container.nodes.$carouselThumbs.index($(this));
        $container.nodes.$carousel.slick('slickGoTo', currentSlide);
        self.selectThumbnail($container, currentSlide);
      });
    },

    initAltVideo: function ($container, movePosters) {
      var self = this;
      if ($container.nodes.$altPoster.length < 1) {
        return;
      }
      if (movePosters) {
        self.moveVideoPosters(self.selector.carouselSlide, $container);
        self.moveVideoPosters(self.selector.carouselThumbs, $container);
        // If we have videos, thumbs need reparsing to get final order.
        $container.nodes.$carouselThumbs = $(self.selector.carouselThumbs, $container.nodes.$carouselThumbsWrapper);
      }
      if (typeof YT.ready !== 'undefined') {
        self.setup($container);
      }
      $(window).on('load', function () {
        self.setup($container);
      });
    },
    moveVideoPosters: function (selector, $context) {
      const self = this;
      const $images = $(selector, $context).not('.slick-cloned');
      let position = 0;
      const $videoWrappers = $images.filter($(self.selector.altPosterWrapper)).not('.slick-cloned');
      const $videoThumbs = $images.filter($(self.selector.carouselVideoThumbs)).not('.slick-cloned');
      const $lastimage = $images.not(self.selector.altPosterWrapper).not(self.selector.carouselVideoThumbs).last();
      let incremental = 1;
      let lastModif = -1;
      let insertPosition = 0;

      $videoWrappers.add($videoThumbs).each(function () {
        const $this = $(this);

        position = $this.find(self.selector.altPoster).data('videoPosition');
        if (lastModif !== -1) {
          incremental = lastModif < position ? incremental + 1 : incremental - 1;
        }
        insertPosition = position - incremental;
        // Position is 1 based, while index is 0.
        if (position !== $images.index($this) + 1) {
          if (position >= $images.length) {
            $this.insertAfter($lastimage);
          } else {
            $this.insertBefore($images[insertPosition]);
          }
          lastModif = position;
        }
      });
    },
    createVideoPlayerDiv: function ($elem, i) {
      var id = 'video-active-' + i;

      $elem.append('<div />').children('div').attr('id', id);

      return id;
    },
    addVideo: function ($wrapper, $that) {
      const self = this;
      const provider = $thisAltVideoPoster.data('videoProvider');

      if (provider.toLowerCase() === 'mp4') {
        // Local ( mp4 ) videos load from mustache
        return;
      }
      const ytId = $wrapper.data('nodes').$altVideoPoster.data('videoSource');
      const ytHeight = $wrapper.data('nodes').$altVideoPoster.height();
      const iframeId = self.createVideoPlayerDiv(
        $wrapper.data('nodes').$player,
        Number(new Date())
      );
      /* global YT */
      // Empty value to stop parameters being eaten by incorrect video IDs with parameters appended
      const player = new YT.Player(iframeId, {
        height: ytHeight,
        width: '100%',
        videoId: ytId,
        playerVars: {
          empty: '',
          controls: Number(!$wrapper.data('autoplay')),
          showinfo: 0,
          rel: 0,
          autoplay: $wrapper.data('autoplay'),
          loop: $wrapper.data('autoplay'),
          mute: $wrapper.data('autoplay'),
          modestbranding: 1
        },
        events: {
          onReady: function (event) {
            event.target.playVideo();
          },
          onStateChange: function (e) {
            if (e.data === YT.PlayerState.ENDED) {
              if ($wrapper.data('autoplay') === 1) {
                player.seekTo(0);
                player.playVideo();
              } else {
                self.hideVideo($wrapper, $that);
              }
            }
          }
        }
      });
    },
    stopAllVideos: function ($container) {
      const self = this;
      $container.nodes.$altPosterWrapper.each(function () {
        const $this = $(this);
        $this.data('nodes')?.$video?.length > 0 && self.toggleVideoPlay($this, true);
      });
    },
    setup: function ($container) {
      const self = this;
      $container.nodes.$altPosterWrapper.each(function () {
        var $this = $(this);
        $this.keyup(function (e) {
          const keyCode = site.getKeycode(e);

          if (keyCode === 13) {
            $this.trigger('click');
          }
        });
        
        if ($this.data('nodes').$player.hasClass('video-active')) {
          // Continue setup for the other posible videos.
          return true;
        }

        if (!$this.data('autoplay')) {
          if ($this.data('nodes').$player.data('localVideo')) {
            self.addLocalVideoEvents($this, $container);
          }
          $this.once('video-toggle').on('click', function (e) {
            e.stopPropagation();
            self.hideVideo($container.nodes.$altPosterWrapper.not($this), $container);
            if ($this.data('nodes').$player.data('localVideo')) {
              self.playLocalVideo($this);
            } else {
              self.addVideo($this, $container);
            }
            // Needs to be after we add the video, so code has height of poster.
            self.showVideo($this, $container);
          });
        } else {
          
          if ($this.data('nodes').$player.data('localVideo')) {
            self.playLocalVideo($this);
          } else {
            self.addVideo($this, $container);
          }
          // Needs to be after we add the video, so code has height of poster.
          self.showVideo($this, $container);
        }
      });
    },
    addLocalVideoEvents: function ($wrapper, $container) {
      const self = this;
      $wrapper.data('nodes').$playIcon.once().on('click', function (e) {
        e.stopPropagation();
        self.playLocalVideo($wrapper);
        self.showVideo($wrapper, $container);
      });
      $wrapper.data('nodes').$video.once().on('click', function (e) {
        e.stopPropagation();
        self.toggleVideoPlay($wrapper, false);
      }).on('ended', function() {
        self.hideVideo($wrapper, $());
        self.resetControls($wrapper);
      });
    },
    toggleVideoPlay: function ($wrapper, pause) {
      const self = this;
      const isPlaying = $wrapper.data('nodes').$video[0].currentTime > 0 && !$wrapper.data('nodes').$video[0].paused;
      const hiddenControlls = $wrapper.data('nodes').$constrols.hasClass('hidden');
      if (self.isMobile && !$wrapper.hasClass('controlls-visible') && hiddenControlls && isPlaying) {
        // For mobile, first click should show the controlls.
        $wrapper.addClass('controlls-visible');
        self.showControls($wrapper);
        return;
      }
      $wrapper.removeClass('controlls-visible');
      if (isPlaying) {
        $wrapper.addClass('video-paused');
        $wrapper.data('nodes').$playIcon.removeClass('hidden');
        $wrapper.data('nodes').$video[0].pause();
        self.showControls($wrapper);
        self.toggleControls($wrapper);
      } else if (!pause) {
        $wrapper.removeClass('video-paused');
        $wrapper.data('nodes').$playIcon.addClass('hidden');
        $wrapper.data('nodes').$video[0].play();
        $wrapper.addClass('video-playing');
        self.showControls($wrapper);
        self.toggleControls($wrapper);
      }
    },
    playLocalVideo: function ($wrapper) {
      const self = this;
      if (!$wrapper.data('nodes').$video.hasClass('video-ready')) {
        $wrapper.data('nodes').$video.addClass('video-ready');
        if ($wrapper.data('nodes').$video[0].readyState !== 4) {
          $wrapper.data('nodes').$video[0].load();
        } else {
          $wrapper.data('nodes').$video[0].play();
        }
        self.setControlsEvent($wrapper);
      }
      $wrapper.addClass('video-playing');
      self.toggleVideoPlay($wrapper, false);
    },
    
    showControls: function ($wrapper) {
      const self = this;
      clearTimeout(self.controllsTimer);
      $wrapper.data('nodes').$constrols.removeClass('hidden');
      self.controllsTimer = setTimeout(function () {
        // Hide controlls when video is not paused.
        if (!$wrapper.hasClass('video-paused')) {
          $wrapper.data('nodes').$constrols.addClass('hidden');
          $wrapper.removeClass('controlls-visible');
        }
      }, 3000),
      self.controllsTimer;
    },
    setControlsEvent: function ($wrapper) {
      const self = this;
      $wrapper.once('controlls-events').on('mousemove', _.throttle(function () {
        !self.isMobile && self.showControls($wrapper);
      }, 200));
      $wrapper.data('nodes').$play.once().on('click', function (e) {
        e.stopPropagation();
        self.toggleVideoPlay($wrapper, false);
      });
      $wrapper.data('nodes').$pause.once().on('click', function (e) {
        e.stopPropagation();
        self.toggleVideoPlay($wrapper, false);
      });
      $wrapper.data('nodes').$mute.once().on('click', function (e) {
        e.stopPropagation();
        $wrapper.data('nodes').$video[0].muted = false;
        $wrapper.data('nodes').$unmute.toggleClass('hidden');
        $wrapper.data('nodes').$mute.toggleClass('hidden');
      });
      $wrapper.data('nodes').$unmute.once().on('click', function (e) {
        e.stopPropagation();
        $wrapper.data('nodes').$video[0].muted = true;
        $wrapper.data('nodes').$unmute.toggleClass('hidden');
        $wrapper.data('nodes').$mute.toggleClass('hidden');
      });
    },
    toggleControls: function ($wrapper) {
      $wrapper.data('nodes').$play.toggleClass('hidden');
      $wrapper.data('nodes').$pause.toggleClass('hidden');
    },
    resetControls: function ($wrapper) {
      $wrapper.data('nodes').$constrols.addClass('hidden');
      $wrapper.data('nodes').$play.addClass('hidden');
      $wrapper.data('nodes').$mute.addClass('hidden');
      $wrapper.data('nodes').$unmute.removeClass('hidden');
      $wrapper.data('nodes').$pause.removeClass('hidden');
    },
    resetLocalVideo: function ($wrapper) {
      $wrapper.data('nodes').$video[0].pause();
      $wrapper.data('nodes').$video[0].muted = false;
      $wrapper.data('nodes').$video[0].load();
    },
    showVideo: function ($wrapper, $that) {
      var self = this;
      if ($wrapper.hasClass('video-paused')) {
        // If video is paused, we no need to "show" it.
        return true;
      }
      // Show active video.
      $wrapper.data('nodes').$player.addClass('video-active').removeClass('hidden');
      $wrapper.data('nodes').$playIcon.addClass('hidden');
      $wrapper.data('nodes').$altVideoPoster.addClass('hidden');

      // When the call is from zoom, there are no nodes on $that.
      $that.nodes?.$container?.toggleClass('alt-video-playing', !Boolean($wrapper.data('autoplay')));
    },
    hideVideo: function ($wrappers, $that) {
      var self = this;
      // Filter out wrappers with autoplay.
      $wrappers.each(function() {
        const $wrapper = $(this);
        const issAutoplay = $wrapper.data('autoplay');

        if (issAutoplay) {
          return;
        }
        $wrapper.removeClass('video-paused').removeClass('video-playing');
        $wrapper.data('nodes').$playIcon.removeClass('hidden');
        $wrapper.data('nodes').$altVideoPoster.removeClass('hidden');
        
        if ($wrapper.data('nodes').$altVideoPoster.data('autoplay')) {
          $wrapper.data('nodes').$playIcon.addClass('hidden');
        }
        $wrapper.data('nodes').$player.removeClass('video-active').addClass('hidden');
        $wrapper.data('nodes').$video.removeClass('video-ready');
        if ($wrapper.data('nodes').$player.data('localVideo')) {
          self.resetLocalVideo($wrapper);
          return;
        }
        // Below code is only for YT videos.
        $wrapper.data('nodes').$player.empty();
        $wrapper.data('nodes').$altVideoPoster.empty();
        // When the call is from zoom, there are no nodes on $that.
        $that.nodes?.$container?.toggleClass('alt-video-playing', Boolean($wrapper.data('autoplay')));
      });
    },
  };
})(jQuery, window.site || {}, window.prodcat || {});
